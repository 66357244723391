import {useState, useEffect} from "react";
import {useLocation, useNavigate, Link} from "react-router-dom";

import {useAuth} from "../hook/useAuth";
import validator from "validator";
import {fetchUserLoginData} from "../poolapi";
import {setToken} from "../utils";

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {signin} = useAuth();


    const fromPage = location.state?.from?.pathname || '/';

    const [login, setRegister] = useState(() => {
        return {
            email: "",
            password: "",
            errorEmail: "",
            errorPass: "",
            checkbox: "",
        }
    })
    const changeCheckboxLogin = (event) => {
        event.persist()

        setRegister(login => {
            if (event.target.name === 'checkbox') {
                login.checkbox = !login.checkbox;
            }
            return login;
        })
    }

    const changeInputLogin = (event)=> {
        event.persist()
        setRegister(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            }
        })
    }
    useEffect(() => {
        return () => {
            setRegister({});
        };
    }, []);

    const submitChackin = async (event) => {
        event.preventDefault();
        login.errorEmail = '';
        login.errorPass = '';

        if (!validator.isEmail(login.email)) {
            login.errorEmail = 'Enter email';
        } else if (!validator.isStrongPassword(login.password, {minSymbols: 0})) {
              login.errorPass =  "Password must consist of one lowercase, uppercase letter and number, at least 8 characters";
           // login.errorPass = "Enter password";
        } else {

            const response = await  fetchUserLoginData(login);
            if (response) {
             signin(response.data.token, () => navigate(fromPage, {replace: true}));

            }else{
                login.errorPass = "Forbidden";
            }

        }
        setRegister(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            }
        })

    }
    return (
        <div className="login">
            <div className="login-content">
                <form onSubmit={submitChackin}>
                    <h1 className="text-center">Sign In</h1>
                    <div className="text-white text-opacity-50 text-center mb-4">
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email Address <span className="text-danger">*</span></label>
                        <input
                            type="email"
                            className="form-control form-control-lg bg-white bg-opacity-5"
                            id="email"
                            name="email"
                            value={login.email}
                            onChange={changeInputLogin}
                        />
                        <div className='invalid-feedback'>{login.errorEmail}</div>
                    </div>
                    <div className="mb-3">
                        <div className="d-flex">
                            <label className="form-label">Password <span className="text-danger">*</span></label>
                            <a href="/" className="ms-auto text-white text-decoration-none text-opacity-50">Forgot
                                password?</a>
                        </div>
                        <input
                            type="password"
                            id="password"
                            className="form-control form-control-lg bg-white bg-opacity-5"
                            name="password"
                            value={login.password}
                            onChange={changeInputLogin}
                        />
                        <div className='invalid-feedback'>{login.errorPass}</div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name='checkbox'
                                onChange={changeCheckboxLogin}
                                id="checkboxo"
                            />
                            <label className="form-check-label" htmlFor="customCheck1">Remember me</label>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Sign In
                    </button>
                    <div className="text-center text-white text-opacity-50">
                        Don't have an account yet? <Link to="/user/reg">Sign up</Link>.
                    </div>
                </form>
            </div>
        </div>
    )
}
export {LoginPage};