import {NavLink, useNavigate, Link, useLocation} from 'react-router-dom';
import './Sidebar.scss';

export function Sidebar() {
    const url = useLocation();
    return (
        <div id="sidebar" className="app-sidebar sidebar">
            <div className="app-sidebar-content" data-scrollbar="true" data-height="100%">

                <div className="menu">
                    <div className="menu-header">Navigation</div>
                    <div className={`menu-item ${url.pathname ==='/dashboard' && ' active'}`}>
                        <NavLink to="/dashboard" className="menu-link">
                            <span className="menu-icon"><i className="bi bi-cpu"></i></span>
                            <span className="menu-text">Dashboard</span>
                        </NavLink>
                    </div>
                    <div className={`menu-item ${url.pathname ==='/profile' && ' active'}`}>
                        <NavLink to="/profile" className="menu-link">
                            <span className="menu-icon"><i className="bi bi-people"></i></span>
                            <span className="menu-text">Profile</span>
                        </NavLink>
                    </div>
                    <div className={`menu-item ${url.pathname ==='/history' && ' active'}`}>
                        <NavLink to="/history" className="menu-link">
                            <span className="menu-icon"><i className="bi bi-clock-history"></i></span>
                            <span className="menu-text">Hisrory</span>
                        </NavLink>
                    </div>
                    <div className="menu-item ">
                        <NavLink to="/pools" className="menu-link">
                            <span className="menu-icon"><i className="bi bi-forward"></i></span>
                            <span className="menu-text">Pools</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}
