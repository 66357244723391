import { SymbolRow } from './SymbolRow';

import './styles.scss';

export function StatsTable({ data = {} }) {
    const renderCoinRow = ([name, data]) => (
        <SymbolRow name={name} data={data} key={name} />
    );

    return (
        <div className="tableRow">
            {Object.entries(data).map(renderCoinRow)}
        </div>
    );
}
