import { createChart } from 'lightweight-charts';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { INTERVAL_TO_SEC } from './utils';
import { IntervalSelect } from './IntervalSelect';
import { fetchChartData, fetchChartLines, fetchChartMarkers } from '../../api';
import { useMountedState } from '../../utils';

import './ChartWidget.scss';

const LINES_INTERVAL = 2000;
const MARKERS_INTERVAL = 10000;

function convertData(rawData) {
    return rawData.map((row) => {
        return {
            time: row[0] / 1000,
            open: +row[1],
            high: +row[2],
            low: +row[3],
            close: +row[4],
        };
    });
}

const darkTheme = {
    chart: {
        layout: {
            backgroundColor: 'transparent',
            lineColor: '#2B2B43',
            textColor: '#D9D9D9',
        },
        watermark: {
            color: 'rgba(0, 0, 0, 0)',
        },
        crosshair: {
            color: '#758696',
        },
        grid: {
            vertLines: {
                color: '#2B2B43',
            },
            horzLines: {
                color: '#363C4E',
            },
        },
    },
    series: {
        upColor: '#3cd2a5',
        downColor: '#ff6060',
    },
};

export function ChartWidget({ configId }) {
    const candleSeries = useRef();
    const chart = useRef(null);
    const chartElement = useRef(null);
    const currentBar = useRef();
    const ws = useRef();
    const resizeTimer = useRef();
    const linesTimer = useRef();
    const markersTimer = useRef();
    const lines = useRef([]);

    const isMounted = useMountedState();

    const [interval, setChartInterval] = useState('30m');

    const onNewData = (data) => {
        if (!currentBar.current) return;

        console.log('next kline', Math.round((data.k.T - data.E) / 1000));

        const price = parseFloat(data.k.c);
        const time = data.E / 1000;

        if (time - currentBar.current?.time >= INTERVAL_TO_SEC[interval]) {
            // NEW BAR
            currentBar.current.open = null;
            currentBar.current.time = time;
        }

        updateCurrentBar(price);
    };

    const updateCurrentBar = (price) => {
        const bar = currentBar.current;

        if (!bar) return;

        if (bar.open === null) {
            bar.open = price;
            bar.high = price;
            bar.low = price;
            bar.close = price;
        } else {
            bar.close = price;
            bar.high = Math.max(bar.high, price);
            bar.low = Math.min(bar.low, price);
        }

        candleSeries.current?.update(bar);
    };

    const fetchData = async () => {
        const response = await fetchChartData(configId, interval);

        if (!isMounted()) return;

        if (response && response.status === '200') {
            const data = convertData(response['candlesticks']);

            if (candleSeries.current && chart.current) {
                chart.current?.removeSeries(candleSeries.current);
            }

            candleSeries.current = chart.current.addCandlestickSeries();
            candleSeries.current.applyOptions(darkTheme.series);
            candleSeries.current.setData(data);

            currentBar.current = data[data.length - 1];

            if (ws.current) {
                ws.current.close();
            }

            ws.current = new ReconnectingWebSocket(response.websocket);
            ws.current.onmessage = (event) => {
                onNewData(JSON.parse(event.data).data);
            };
        }

        return response;
    };

    const fetchLines = async () => {
        const response = await fetchChartLines(configId);

        if (!isMounted()) return;

        if (response) {
            lines.current.forEach((line) =>
                candleSeries.current.removePriceLine(line)
            );

            response.lines.forEach((line) => {
                const priceLine = candleSeries.current.createPriceLine({
                    ...line,
                });

                lines.current.push(priceLine);
            });
        }

        linesTimer.current = setTimeout(fetchLines, LINES_INTERVAL);
    };

    const fetchMarkers = async () => {
        const response = await fetchChartMarkers(configId);

        if (!isMounted()) return;

        if (response) {
            candleSeries.current.setMarkers(
                response.markers.map((marker) => ({
                    ...marker,
                    time: Math.round(marker.time / 1000),
                }))
            );
        }

        markersTimer.current = setTimeout(fetchMarkers, MARKERS_INTERVAL);
    };

    const initData = () => {
        fetchData().then(() => {
            fetchLines();
            fetchMarkers();
        });
    };

    const onResize = useCallback(() => {
        if (resizeTimer.current) clearTimeout(resizeTimer.current);

        resizeTimer.current = setTimeout(() => {
            chart.current.resize(
                chartElement.current.parentNode.clientWidth,
                chartElement.current.parentNode.clientHeight
            );
        }, 300);
    }, []);

    useEffect(() => {
        chart.current = createChart(chartElement.current, {
            width: chartElement.current.parentNode.clientWidth,
            height: chartElement.current.parentNode.clientHeight - 100,
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
            },
        });

        chart.current.applyOptions(darkTheme.chart);
        initData();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);

            clearTimeout(linesTimer.current);
            clearTimeout(markersTimer.current);

            if (ws.current) {
                ws.current.close();
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval]);

    return (
        <div className="chart-widget">
            <IntervalSelect  onChange={setChartInterval} value={interval} />
            <div className="candle-chart" ref={chartElement} />
        </div>
    );
}
