import {useEffect, useRef, useState} from 'react';
//import {Link} from 'react-router-dom';
import {fetchDashboardData} from '../api';
import {StatsTable} from '../tables';
import {useMountedState} from '../utils';

import './Dashboard.scss';
import {Header} from "../shared/header";
import {Sidebar} from "../shared/sidebar";

const UPDATE_PERIOD = 1000;

export function Dashboard() {
    const [data, setData] = useState(undefined);
    const [balance, setBalance] = useState({});
    const timerId = useRef(null);
    const isMounted = useMountedState();

    const fetchData = async () => {
        const response = await fetchDashboardData();
        if (!isMounted()) return;
        if (response) {
            const {data, balance} = response;
            setBalance(balance);
            setData(data);
        }

        timerId.current = setTimeout(fetchData, UPDATE_PERIOD);
    };

    useEffect(() => {
        fetchData();
        return () => {
            clearTimeout(timerId.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id='app' className="app">
            <div className="dashboard ">
                <Header>

                </Header>

                <Sidebar>

                </Sidebar>
                <button className="app-sidebar-mobile-backdrop" data-toggle-target=".app"
                        data-toggle-class="app-sidebar-mobile-toggled"/>
                <div id='content' className=" app-content">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">BREADCRUMB</a></li>
                        <li className="breadcrumb-item active">HOME PAGE</li>
                    </ul>
                    <h1 className="page-header">
                        Starter Page <small>.</small>
                    </h1>
                    <p>
                        Start build your page here
                    </p>
                    <StatsTable data={data}/>
                    {data && (
                        <span>
                    Balance: {balance.sum_total} + {balance.usdt} ={' '}
                            {(balance.sum_total + balance.usdt).toFixed(2)}
                </span>
                    )}
                </div>
            </div>
        </div>
    );
}
