import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useMountedState, usePrevious } from '../../utils';

import './Cell.scss';

export const Cell = React.memo(function ({
    children,
    className,
    ...restProps
}) {
    return (
        <div {...restProps} className={cn('cell', className)}>
            {children}
        </div>
    );
});

export function CellUpDown({ children, value, className, ...restProps }) {
    const [dir, setDir] = useState(null);
    const timerId = useRef(null);
    const prevValue = usePrevious(value);
    const isMounted = useMountedState();

    useEffect(() => {
        if (value > prevValue) {
            setDir('up');
        }
        if (value < prevValue) {
            setDir('down');
        }

        timerId.current = setTimeout(() => {
            if (isMounted()) {
                setDir(null);
            }
        }, 500);
    }, [value, prevValue, isMounted]);

    useEffect(() => {
        return () => {
            clearTimeout(timerId.current);
        };
    }, []);

    return (
        <Cell
            {...restProps}
            className={cn('cell--up-down', className, dir && `cell--${dir}`)}
        >
            {children}
        </Cell>
    );
}
