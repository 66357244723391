import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from 'react-router-dom';
import {Dashboard} from './dashboard';
//import {ConfigPage} from './config-page';
import {HistoryPage} from './history-page';
//import {User} from './user';
//import {getToken} from './utils';
import './App.css';
import {Error} from "./shared/error";
import {ProfilePage} from "./profile-page";
import {ConfigPage} from "./config-page";
import {Main} from "./main";
import {RequireAuth} from "./hoc/RequireAuth";
import {AuthProvider} from "./hoc/AuthProvider";
import {LoginPage} from "./page/LoginPage";

function App() {
    //  const isAuth = !!getToken();
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/dashboard" element={
                        <RequireAuth>
                            <Dashboard/>
                        </RequireAuth>
                    }/>
                    <Route path="/config/:id" element={
                        <RequireAuth>
                            <ConfigPage/>
                        </RequireAuth>
                    }/>
                    {/*{isAuth && (*/}
                    {/*    <Route path="/config/:id" component={ConfigPage}/>*/}
                    {/*)}*/}
                    <Route path="/history" element={
                        <RequireAuth>
                            <HistoryPage/>
                        </RequireAuth>
                    }/>
                    <Route path="/profile" element={
                        <RequireAuth>
                            <ProfilePage/>
                        </RequireAuth>
                    }/>
                    <Route path="/login" element={<LoginPage/>}/>

                    <Route path="/" element={<Main/>}/>
                    <Route path='*' element={<Error/>}/>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
