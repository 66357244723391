import axios from 'axios';

export const API_URL = 'https://nordstar.space/vasa/api';

axios.defaults.headers.common = {
    'access-key': localStorage.getItem('access-key'),
};

export const updateParam = async (configId, param, value) => {
    const params = {
        config_id: configId,
        [param]: +value,
    };

    let endpoint;
    if (param === 'quantity') endpoint = '/quantity';
    if (param === 'inicial') endpoint = '/inicial';
    if (param === 'p_buy') endpoint = '/trade-buy';
    if (param === 'p_sell') endpoint = '/trade-sell';
    if (param === 'dwa') endpoint = '/trade-wall-ask';
    if (param === 'dwb') endpoint = '/trade-wall-bid';
    if (param === 'trade') endpoint = '/trade';
    if (param === 'max_count_sell') {
        // TODO :
        endpoint = '/max-sell';
        delete params[param];
        params['max_sell'] = +value;
    }
    if (param === 'max_count_buy') {
        // TODO:
        endpoint = '/max-buy';
        delete params[param];
        params['max_buy'] = +value;
    }

    if (!endpoint) {
        alert(`Неизвестный параметр ${param}`);
    }

    if (!window.confirm(`Обновить ${param} на ${value}?`)) return;

    try {
        await axios.get(API_URL + endpoint, {
            params,
        });
    } catch (e) {
        alert(e);
    }
};

export const fetchDashboardData = async () => {
    try {
        const response = await axios.get(API_URL + '/index');
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const fetchConfigData = async (configId) => {
    try {
        const response = await axios.get(API_URL + '/index', {
            params: {
                config_id: configId,
            },
        });
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const fetchHistory = async () => {
    try {
        const response = await axios.get(API_URL + '/history');
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const fetchChartData = async (configId, interval) => {
    try {
        const response = await axios.get(
            API_URL + `/candlesticks?config_id=${configId}&interval=${interval}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const fetchChartLines = async (configId) => {
    try {
        const response = await axios.get(
            API_URL + `/lines?config_id=${configId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const fetchChartMarkers = async (configId) => {
    try {
        const response = await axios.get(
            API_URL + `/markers?config_id=${configId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const start = async (configId) => {
    try {
        return await axios.get(API_URL + '/start', {
            params: {
                config_id: configId,
            },
        });
    } catch (e) {
        alert(e);
    }
};

export const stop = async (configId) => {
    try {
        return await axios.get(API_URL + '/stop', {
            params: {
                config_id: configId,
            },
        });
    } catch (e) {
        alert(e);
    }
};
