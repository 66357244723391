/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { start, stop } from '../../api';

const RUNNING = '1';
const LOADING = '2';
const STOPPED = '3';

export function Actions({ status, configId }) {
    const [pending, setPending] = useState(false);

    const handleStart = () => {
        if (!window.confirm('Запустить?')) return;
        setPending(true);
        start(configId).finally(() => setPending(false));
    };

    const handleStop = () => {
        if (!window.confirm('Остановить?')) return;
        setPending(true);
        stop(configId).finally(() => setPending(false));
    };

    return pending || status === LOADING ? (
        <span>В процессе</span>
    ) : (
        <>
            {status === STOPPED && (
                <a className="action" onClick={handleStart}>
                    Запустить
                </a>
            )}
            {status === RUNNING && (
                <a className="action" onClick={handleStop}>
                    Остановить
                </a>
            )}
        </>
    );
}
