import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
//import {Link} from 'react-router-dom';

import {ChartWidget} from './chart-widget/ChartWidget';
import {StatsTable} from '../tables';
import {fetchConfigData} from '../api';
import {useMountedState} from '../utils';

import './ConfigPage.scss';
import {Header} from "../shared/header";
import {Sidebar} from "../shared/sidebar";

const UPDATE_PERIOD = 1000;

export function ConfigPage() {
    const params = useParams();
    const [data, setData] = useState(undefined);
    const timerId = useRef(null);
    const isMounted = useMountedState();

    const fetchData = async () => {
        const response = await fetchConfigData(params.id);
        if (!isMounted()) return;
        if (response) {
            const {data} = response;
            setData(data);
        }
        timerId.current = setTimeout(fetchData, UPDATE_PERIOD);
    };

    useEffect(() => {
        fetchData();
        return () => {
            clearTimeout(timerId.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

   // console.log(data['USDT/BTC'])


    return (
        <div id='app' className="app">
            <div className="config-page ">
                <Header>
                </Header>
                <Sidebar>
                </Sidebar>
                <button className="app-sidebar-mobile-backdrop" data-toggle-target=".app"
                        data-toggle-class="app-sidebar-mobile-toggled"/>
                <div id='content' className=" app-content">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">DASHBOARD</a></li>
                        <li className="breadcrumb-item active">CONFIG </li>
                    </ul>
                    <h1 className="page-header">
                        Starter Page <small>page header description goes here...</small>
                    </h1>
                    <p>
                        Start build your page here
                    </p>
                    <StatsTable data={data}/>
                    <p></p>
                    <div className="card p-3 bg-none">
                        <div className="card-header fw-bold ">dlf;d</div>
                        <div className="card-body">
                            <ChartWidget configId={params.id}/>
                        </div>
                        <div className="card-arrow">
                            <div className="card-arrow-top-left"></div>
                            <div className="card-arrow-top-right"></div>
                            <div className="card-arrow-bottom-left"></div>
                            <div className="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
