import axios from 'axios';

export const API_URL = 'https://nordstar.space/pool/api';





export const fetchMainData = async () => {
    try {
        const response = await axios.get(API_URL + '/main');
        return response.data;
    } catch (e) {
        console.error(e);
        return null;
    }
};








