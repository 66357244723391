import {useLocation, Navigate} from 'react-router-dom';
import {useAuth} from "../hook/useAuth";
import {getToken} from "../utils";

const RequireAuth = ({children}) => {
    const location = useLocation();
    const {user} = useAuth();

    if (!user || !getToken()) {
        return <Navigate to='/login' state={{from: location}}/>
    }
    return children;
}
export {RequireAuth};