import axios from 'axios';

export const API_URL = 'https://nordstar.space/pool';

axios.defaults.headers.common = {
    'access-key': localStorage.getItem('access-key'),
};


export const fetchUserLoginData =  (login) => {
        try {
            const response =  axios({
                url: API_URL + '/user/login',
                method:'post',
                responseType: 'json',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    email: login.email,
                    password: login.password,
                    remember: login.remember,
                },
            });
            return response;
        } catch
            (e) {
            console.error(e);
            return null;
        }
    }
;




