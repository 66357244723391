import {createContext, useState} from "react";
import {removeToken, setToken, getToken} from '../utils';

export const AuthContext = createContext(null);
export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    const signin = (newUser, cb) => {
        setUser(newUser);
        setToken(newUser);
        cb();
    }
    const signout = (cb) => {
        setUser(null);
        removeToken();
        cb();
    }

    if (user == null && getToken() != null) {
        setUser(getToken());
    }
    const value = {user, signin, signout}
    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}