import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Input} from '../shared/Input/Input';
import {getToken, setToken} from '../utils';


import './ProfilePage.scss';
import {Header} from "../shared/header";
import {Sidebar} from "../shared/sidebar";
import {useAuth} from "../hook/useAuth";

export function ProfilePage() {
    const token = getToken();

    const {signout} = useAuth();

    const navigate = useNavigate();

    const handleTokenInputBlur = (event) => {
        setToken(event.target.value);

        window.location.href = '/';
    };

    return (
        <div id='app' className="app">
            <div className="profile-page">
                <Header>
                </Header>
                <Sidebar>
                </Sidebar>

                <button className="app-sidebar-mobile-backdrop" data-toggle-target=".app"
                        data-toggle-class="app-sidebar-mobile-toggled"/>
                <div id='content' className=" app-content">

                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">BREADCRUMB</a></li>
                        <li className="breadcrumb-item active">HOME PAGE</li>
                    </ul>

                    <h1 className="page-header">
                        Starter Page <small>page header description goes here...</small>
                    </h1>

                    <p>
                        Start build your page here
                    </p>
                    <div className="form">
                        <Input
                            label="Token"
                            defaultValue={token}
                            onBlur={handleTokenInputBlur}
                        />
                    </div>


                </div>
            </div>
        </div>
    );
}
