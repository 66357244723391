import './IntervalSelect.scss';
import {
    INTERVAL_1M,
    INTERVAL_1D,
    INTERVAL_5M,
    INTERVAL_15M,
    INTERVAL_30M,
    INTERVAL_1H,
} from './utils';

export const IntervalSelect = ({ value, onChange }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <select
            className="interval-select form-select"
            value={value}
            onChange={handleChange}
        >
            {[
                INTERVAL_1M,
                INTERVAL_5M,
                INTERVAL_15M,
                INTERVAL_30M,
                INTERVAL_1H,
                INTERVAL_1D,
            ].map((interval) => (
                <option value={interval} key={interval}>
                    {interval}
                </option>
            ))}
        </select>
    );
};
