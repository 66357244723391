import { useCallback, useEffect, useRef } from 'react';

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export function useMountedState() {
    const mountedRef = useRef(false);
    const get = useCallback(() => mountedRef.current, []);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return get;
}

export function getToken() {
    return localStorage.getItem('access-key');
}

export function setToken(token) {
    localStorage.setItem('access-key', token);
}
export function removeToken() {
    localStorage.removeItem('access-key');
}
