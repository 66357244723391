import {Link} from 'react-router-dom';
import cn from 'classnames';
import {Cell, CellUpDown} from '../../shared/Cell';
import {Editable} from '../../shared/Editable';
import {updateParam} from '../../api';
import {Actions} from '../Actions/Actions';

import './SymbolRow.scss';

export function SymbolRow({name, data}) {
    const {coin, config, day, noon, week} = data;

    return (
        <div className="symbol-row row">
            <div className="col-lg-2 col-xl-2 mb-2">
                <div className="card p-2">
                    <CellUpDown style={{minWidth: 140}} value={coin.ask}>
                        <Link className="symbol-row__coin" to={`/config/${config.id}`}>
                            {name}
                        </Link>
                        <div>{coin.ask}</div>
                    </CellUpDown>
                    <Cell>{`${coin.countSELL}`}</Cell>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>

            <div className="col-lg-2 col-xl-2 mb-2">
                <div className="card p-2">
                    <Cell>
                        <Editable
                            onChanged={(value) =>
                                updateParam(config.id, 'inicial', value)
                            }
                        >
                            {coin.inicial}
                        </Editable>
                    </Cell>
                    <Cell style={{minWidth: 200}}>
                        <div>
                            ask {coin.ask_r} | {coin.proc_sell}
                        </div>
                        <div>
                            bid {coin.bid_r} | {coin.proc_buy}
                        </div>
                    </Cell>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 col-xl-2 mb-2">
                <div className="card p-2">
                    <Cell>
                        <div>
                            sell{' '}
                            <Editable
                                onChanged={(value) =>
                                    updateParam(config.id, 'p_sell', value)
                                }
                            >
                                {config.p_sell}
                            </Editable>
                        </div>
                        <div>
                            buy{'  '}
                            <Editable
                                onChanged={(value) =>
                                    updateParam(config.id, 'p_buy', value)
                                }
                            >
                                {config.p_buy}
                            </Editable>
                        </div>
                    </Cell>
                    <Cell>
                        <div>
                            ask{' '}
                            <Editable
                                onChanged={(value) =>
                                    updateParam(config.id, 'dwa', value)
                                }
                            >
                                {config.dwa}
                            </Editable>
                        </div>
                        <div>
                            bid{' '}
                            <Editable
                                onChanged={(value) =>
                                    updateParam(config.id, 'dwb', value)
                                }
                            >
                                {config.dwb}
                            </Editable>
                        </div>
                    </Cell>
                    <Cell>
                        <Editable
                            onChanged={(value) =>
                                updateParam(config.id, 'quantity', value)
                            }
                        >
                            {coin.quantity_buy}
                        </Editable>
                    </Cell>

                    <Cell style={{width: 100}}>
                        <div>
                            sell{' '}
                            <Editable
                                onChanged={(value) =>
                                    updateParam(config.id, 'max_count_sell', value)
                                }
                            >
                                {config.max_count_sell}
                            </Editable>
                        </div>
                        <div>
                            buy{'  '}
                            <Editable
                                onChanged={(value) =>
                                    updateParam(config.id, 'max_count_buy', value)
                                }
                            >
                                {config.max_count_buy}
                            </Editable>
                        </div>
                    </Cell>

                    <Cell>
                        <b>{coin.total}</b>
                    </Cell>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 col-xl-2 mb-2">
                <div className="card p-2">
                    <Cell
                        value={noon.price}
                        style={{minWidth: 120}}
                        className={cn('text-center', {
                            'cell--up': noon.price > 0,
                            'cell--down': noon.price < 0,
                        })}
                    >
                        {noon.price}
                    </Cell>
                    <Cell
                        value={day.price}
                        style={{minWidth: 120}}
                        className={cn('text-center', {
                            'cell--up': day.price > 0,
                            'cell--down': day.price < 0,
                        })}
                    >
                        {day.price}
                    </Cell>
                    <Cell
                        value={week.price}
                        style={{minWidth: 120}}
                        className={cn('text-center', {
                            'cell--up': week.price > 0,
                            'cell--down': week.price < 0,
                        })}
                    >
                        {week.price}
                    </Cell>
                    <CellUpDown
                        value={config.price_buy}
                        style={{minWidth: 120}}
                        className="text-center"
                    >
                        {config.price_buy}
                    </CellUpDown>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 col-xl-2 mb-2">
                <div className="card p-2">
                    <Cell>
                        <Editable
                            onChanged={(value) =>
                                updateParam(config.id, 'trade', value)
                            }
                        >
                            {config.trade}
                        </Editable>
                    </Cell>
                    <Cell>
                        <Actions status={config.status} configId={config.id}/>
                    </Cell>
                    <div className="card-arrow">
                        <div className="card-arrow-top-left"></div>
                        <div className="card-arrow-top-right"></div>
                        <div className="card-arrow-bottom-left"></div>
                        <div className="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
