import React, { useState } from 'react';
import ContentEditable from 'react-contenteditable';

export const Editable = React.memo(
    function Editable({
        children,
        onChanged = async () => {
            return;
        },
    }) {
        const [pending, setPending] = useState(false);

        const handleBlur = async (event) => {
            const newValue = event.target.innerHTML?.trim();
            if (newValue !== children) {
                setPending(true);
                await onChanged(newValue);
                setTimeout(() => {
                    setPending(false);
                }, 1000);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter' || event.key === 'Escape') {
                event.target.blur();
            }
        };

        return (
            <span>
                {pending ? (
                    <span>...</span>
                ) : (
                    <ContentEditable
                        tagName="span"
                        html={children}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                    />
                )}
            </span>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.children === nextProps.children;
    }
);
