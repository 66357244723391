import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
//import {Input} from '../shared/Input/Input';
//import {getToken, setToken} from '../utils';

import './Main.scss';
import {Header} from "../shared/header";
import {Chart} from "react-google-charts";
import {fetchMainData} from "../api-pool";

const UPDATE_PERIOD = 2000;
export const colum = [
    [
        {type: "datetime", label: "data"},
        "Price",
        {type: "string", role: "annotation"},
        "Sell",
        "Buy",
    ]

];


export const options = {
        title: 'BTC',
        height: 500,
        backgroundColor: '',
        chartArea: {
            left: '80',
            top: '50',
            width: '93%',
            height: '80%'
        },
        titleTextStyle: {color: '#fff'},
        series: {
            0: {
                color: '#fff',
            },
            1: {
                color: '#891801',
            },
            2: {
                color: '#23ad7d',
            },
            3: {
                color: '#FF1500',
            }
        },
        legend:
            {
                textStyle:
                    {
                        color: '#fff'
                    },
                position: 'top'
            },
        annotations: {
            boxStyle: {
                stroke: '#014242',
                strokeWidth: 1,
                rx: 2,
                ry: 2,
                gradient: {
                    color1: '#014242',
                    color2: '#027d7d',
                    x1: '0%', y1: '0%',
                    x2: '100%', y2: '100%',
                    useObjectBoundingBoxUnits: true
                }
            }
        },
        hAxis: {
            titleTextStyle: {
                color: '#fff'
            },
            textStyle: {
                color: '#fff'
            },
            gridlines: {
                color: 'none'
            },
            format: 'mm',
        },
        vAxis: {
            gridlines: {
                color: '#014242'
            },
            titleTextStyle: {
                color: '#fff'
            },
            textStyle: {
                color: '#fff'
            },
        }
    }
;


export function Main() {
    //  const token = getToken();
    //   const handleTokenInputBlur = (event) => {
    //       setToken(event.target.value);
    //       window.location.href = '/';
    //   };
    const [data, setData] = useState(undefined);
    const timerId = useRef(null);
    const fetchData = async () => {
        const response = await fetchMainData();
        if (response) {
            //  console.log(response.data);
            const {data} = response;
            setData(data);
        }
        timerId.current = setTimeout(fetchData, UPDATE_PERIOD);
    };
    useEffect(() => {
        fetchData();
        return () => {
            clearTimeout(timerId.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const datas = colum.concat(data);

    return (
        <div id='app' className="app">
            <div className="main">
                <Header>
                </Header>
                <div className="coming-soon">
                    <div className="flex-1">
                        <div className="container">
                            <div className="coming-soon-box">
                                <div className="bg-white bg-opacity-25 p-5 rounded-3">
                                    <h1 className="display-4">Surup Pools</h1>
                                    <p className="lead">This is a simple hero unit, a simple jumbotron-style component
                                        for
                                        calling extra attention to featured content or information This is a simple hero
                                        unit, a simple jumbotron-style component for
                                        calling extra attention to featured content or information.</p>
                                    <hr className="my-4"></hr>
                                    <p>It uses utility classes for typography and spacing to space content out within
                                        the
                                        larger container.</p>

                                </div>
                            </div>
                            <div className="p-5">
                                <div className="card chart-main">

                                    <div className="card-body ">
                                        <Chart
                                            chartType="LineChart"
                                            width="100%"
                                            height="400px"
                                            data={datas}
                                            options={options}
                                        />

                                    </div>
                                    <div className="card-arrow">
                                        <div className="card-arrow-top-left"></div>
                                        <div className="card-arrow-top-right"></div>
                                        <div className="card-arrow-bottom-left"></div>
                                        <div className="card-arrow-bottom-right"></div>
                                    </div>
                                </div>

                            </div>
                            <div className="  coming-soon-box ">
                                <div className="col-lg-4 col-xl-4 mb-3">
                                    <img alt='Img' width='600px' src='/assets/img/50-50s.png'/>
                                </div>
                                <div className="col-lg-4 col-xl-4 mb-3">

                                </div>
                                <div className="col-lg-4 col-xl-4 mb-3">

                                </div>
                            </div>
                        </div>
                        <div className="coming-soon-content d-flex flex-column">
                            <div className="flex-1 mb-3">
                                <h2 className="mb-3">We're coming soon!</h2>
                                <p className="mb-4">We are working very hard on the new version of our site. It will
                                    bring a
                                    lot of new features. Stay tuned!</p>
                                <div className="input-group mb-2">
                                    <input type="text" className="form-control" placeholder="Email Address"/>
                                    <button type="submit" className="btn btn-outline-theme">SUBSCRIBE</button>
                                </div>
                                <div className="mb-1 small text-white text-opacity-50">* Subscribe and get notified for
                                    latest news
                                </div>
                            </div>
                            <div className="text-center small text-white text-opacity-50">
                                © 2022 SeanTheme Right Reserved
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
