export const INTERVAL_1M = '1m';
export const INTERVAL_5M = '5m';
export const INTERVAL_15M = '15m';
export const INTERVAL_30M = '30m';
export const INTERVAL_1H = '1h';
export const INTERVAL_1D = '1d';

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const INTERVAL_TO_SEC = {
    [INTERVAL_1M]: MINUTE,
    [INTERVAL_5M]: 5 * MINUTE,
    [INTERVAL_15M]: 15 * MINUTE,
    [INTERVAL_30M]: 30 * MINUTE,
    [INTERVAL_1H]: HOUR,
    [INTERVAL_1D]: DAY,
};
