import {useEffect, useState} from 'react';
//import {Link} from 'react-router-dom';
import {fetchHistory} from '../api';
import {Cell} from '../shared/Cell';
import './HistoryPage.scss';
import {Header} from "../shared/header";
import {Sidebar} from "../shared/sidebar";

export function HistoryPage() {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchHistory().then(setData);
    }, []);

    const renderHistoryRow = (operation, idx) => (
        <tr key={idx + 1}>
            <td className="py-1 align-middle"><Cell>{idx + 1}</Cell></td>
            <td className="py-1 align-middle"><Cell>{operation.symbol}</Cell></td>

            <td className="py-1 align-middle"><Cell>{operation.quantity}</Cell></td>
            <td className="py-1 align-middle"><Cell>{operation.price}</Cell></td>
            <td className="py-1 align-middle"><span className={`badge border ${Number(operation.buy) ? 'border-success text-success' : 'border-warning text-warning'}  px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}>
                <i className="fa fa-circle fs-9px fa-fw me-5px"></i> <Cell>{Number(operation.buy) ? 'BYU' : 'SELL'}</Cell></span></td>

            <td className="py-1 align-middle"><Cell>{Date.parse(operation.addtime)}</Cell></td>
        </tr>
    );

    return (
        <div id='app' className="app">
            <div className="history-page">

                <Header>

                </Header>
                <Sidebar>

                </Sidebar>
                <button className="app-sidebar-mobile-backdrop" data-toggle-target=".app"
                        data-toggle-class="app-sidebar-mobile-toggled"/>


                <div id='content' className=" app-content">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">BREADCRUMB</a></li>
                        <li className="breadcrumb-item active">HOME PAGE</li>
                    </ul>

                    <h1 className="page-header">
                        Starter Page <small>page header description goes here...</small>
                    </h1>

                    <p>
                        Start build your page here
                    </p>
                    <table className="table table-hover text-nowrap ">
                        <thead>
                        <tr>
                            <th className="border-top-0 pt-0 pb-2">#</th>
                            <th className="border-top-0 pt-0 pb-2">Symbol</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Тип сделки</th>
                            <th>Время</th>
                        </tr>
                        </thead>
                        <tbody>{data?.history.map(renderHistoryRow)}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
